import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { addDays } from 'date-fns'
import { default as DatePicker } from 'react-datepicker'

import {
  Flex,
  Input,
  Button,
  Text,
  Stack,
  SimpleGrid,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Textarea,
} from '@chakra-ui/react'

import 'react-datepicker/dist/react-datepicker.css'

function FormWedding({
  hideOnSuccess,
  onSubmit,
  name,
  event,
  phone,
  email,
  firstDate,
  guests,
  message,
  successText,
  buttonText,
}) {
  const [success, setSuccess] = useState(false)
  const formik = useFormik({
    initialValues: {
      name: '',
      event: '',
      phone: '',
      email: '',
      firstDate: null,
      guests: 1,
      message: '',
    },
    validate: (values) => {
      const errors = {}

      if (!values.name) {
        errors.name = name.required
      }

      if (!values.event) {
        errors.event = event.required
      }

      if (!values.email) {
        errors.email = email.required
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = email.invalid
      }

      if (!values.firstDate) {
        errors.firstDate = firstDate.required
      }

      return errors
    },
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values, (success) => setSuccess(success))
      setSubmitting(false)
    },
  })

  if (hideOnSuccess && success) {
    return (
      <Flex padding={10} justify="center">
        <Text fontWeight="bold" fontSize="xl" textAlign="center">
          {successText}
        </Text>
      </Flex>
    )
  }

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    errors,
    values,
    isSubmitting,
    setFieldValue,
  } = formik

  return (
    <form onSubmit={handleSubmit}>
      <SimpleGrid columns={{ base: 1, md: 2 }} width="100%" direction="column" spacing={4}>
        <FormControl isRequired isInvalid={errors.name && touched.name}>
          <FormLabel htmlFor="name">{name.label}</FormLabel>
          <Input
            variant="filled"
            type="name"
            id="name"
            placeholder={name.placeholder}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormErrorMessage>{errors.name}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={errors.event && touched.event}>
          <FormLabel htmlFor="event">{event.label}</FormLabel>
          <Input
            variant="filled"
            type="event"
            id="event"
            placeholder={event.placeholder}
            value={values.event}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormErrorMessage>{errors.event}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={errors.email && touched.email}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            variant="filled"
            placeholder={email.placeholder}
            type="email"
            id="email"
            aria-describedby="email-helper-text"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormErrorMessage>{errors.email}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="phone">{phone.label}</FormLabel>
          <Input
            variant="filled"
            type="phone"
            id="phone"
            placeholder={phone.placeholder}
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormControl>

        <FormControl isRequired isInvalid={errors.firstDate && touched.firstDate}>
          <FormLabel htmlFor="firstDate">{firstDate.label}</FormLabel>
          <DatePicker
            id="firstDate"
            customInput={<Input variant="filled" />}
            selected={values.firstDate}
            minDate={addDays(new Date(), 1)}
            onChange={(e) => setFieldValue('firstDate', e)}
            onBlur={handleBlur}
          />
          <FormErrorMessage>{errors.firstDate}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={errors.guests && touched.guests}>
          <FormLabel htmlFor="guests">{guests.label}</FormLabel>
          <Input
            variant="filled"
            type="number"
            min={1}
            id="guests"
            placeholder={guests.placeholder}
            value={values.guests}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormErrorMessage>{errors.guests}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>
      <Stack spacing={4} marginTop={6}>
        <FormControl>
          <FormLabel htmlFor="message">{message.label}</FormLabel>
          <Textarea
            variant="filled"
            id="message"
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormControl>
        <Button colorScheme="primary" size="lg" type="submit" isDisabled={isSubmitting}>
          {buttonText}
        </Button>
      </Stack>
    </form>
  )
}

FormWedding.propTypes = {
  hideOnSuccess: PropTypes.bool,
  onSuccess: PropTypes.func,
}

FormWedding.defaultProps = {
  hideOnSuccess: false,
  onSuccess: () => {},
}

export default FormWedding
